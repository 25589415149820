import classNames from "classnames"
import { Link } from "gatsby"
import React from "react"

import styles from "./buttonRounded.module.css"
import LinkArrow from "../../images/LinkArrow"

const ButtonRounded = ({
  bgColor,
  to,
  text,
  onClick,
  colored,
  className = "",
  isExternal,
}) => {
  if (isExternal) {
    return (
      <a
        href={to}
        target="_blank"
        className={classNames(styles.button, className, {
          [styles.buttonReverse]: !colored,
        })}
        onClick={onClick}
      >
        <span className={styles.text}>{text}</span>
        <span className={styles.arrow}>
          <LinkArrow size={24} fill="#0B042D" />
        </span>
      </a>
    )
  }
  return (
    <Link
      to={to}
      className={classNames(styles.button, className, {
        [styles.buttonReverse]: !colored,
      })}
      onClick={onClick}
    >
      <span className={styles.text}>{text}</span>
      <span className={styles.arrow}>
        <LinkArrow size={24} fill="#0B042D" />
      </span>
    </Link>
  )
}

export default ButtonRounded
