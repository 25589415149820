import React from "react"

import styles from "./section.module.css"

const Section = ({ bgColor, foldedCorners, children }) => {
  return (
    <div className={styles.container}>
      <div className={styles.innerContainer}>{children}</div>
    </div>
  )
}

export default Section
