import React from "react"

import office from "../../images/services/office.png"

import styles from "./projectBanner.module.css"

const ProjectBanner = ({ content: { title, description, buttonText } }) => {
  //TODO finish this comp
  return (
    <div className={styles.container}>
      <div className={styles.gridItem1}>
        <img className={styles.image} alt="office" src={office} />
      </div>
      <div className={styles.gridItem2}>
        <div className={styles.title}>{title}</div>
        <div className={styles.title}></div>
        <div className={styles.title}>button</div>
      </div>
    </div>
  )
}

export default ProjectBanner
