import React from "react"

import Section from "../Section"
import LinkArrow from "../../images/LinkArrow"
import supabase from "../../images/services/supabase.png"
import vercel from "../../images/services/vercel.png"
import shopify from "../../images/services/shopify.png"
import twilio from "../../images/services/twilio.png"
import reactnative from "../../images/services/reactnative.png"
import prismic from "../../images/services/prismic.png"
import stripe from "../../images/services/stripe.png"
import algolia from "../../images/services/algolia.png"
import firebase from "../../images/services/firebase.png"

import styles from "./ourTools.module.css"
import CornerContainer from "../CornerContainer/CornerContainer"
import { Link } from "gatsby"

const OurTools = ({
  content: {
    smallTitle,
    title,
    description,
    consultationBoxTitle,
    consultationBoxButtonText,
    exploreLinkText,
  },
}) => {
  return (
    <CornerContainer backgroundColor="colorOffWhite" overlapSpacing>
      <div className={styles.container}>
        <div className={styles.innerContainer}>
          <div className={styles.gridItem}>
            <div className={styles.smallTitle}>{smallTitle}</div>
            <div className={styles.title}>{title}</div>
            <div className={styles.description}>{description}</div>
            <div className={styles.consultationBox}>
              <div className={styles.consultationBoxTitle}>
                {consultationBoxTitle}
              </div>
              <Link
                target="_blank"
                to="https://calendly.com/undefined-siebe/discovery-call"
                className={styles.button}
              >
                <div className={styles.dot} />
                {consultationBoxButtonText}
              </Link>
            </div>
            <Link to="/toolkit" className={styles.exploreLink}>
              {exploreLinkText} <LinkArrow size={9} fill={"#000000"} />
            </Link>
          </div>
          <div className={styles.gridItem2}>
            <div className={styles.toolsContainer}>
              <div className={styles.toolsStrip1}>
                <div className={styles.toolLogo}>
                  <img src={supabase} alt="supabase" />
                </div>
                <div className={styles.toolLogo}>
                  <img src={shopify} alt="shopify" />
                </div>
                <div className={styles.toolLogo}>
                  <img src={twilio} alt="twilio" />
                </div>
              </div>
              <div className={styles.toolsStrip2}>
                <div className={styles.toolLogo}>
                  <img src={reactnative} alt="reactnative" />
                </div>
                <div className={styles.toolLogo}>
                  <img src={firebase} alt="firebase" />
                </div>
                <div className={styles.toolLogo}>
                  <img src={vercel} alt="vercel" />
                </div>
              </div>
              <div className={styles.toolsStrip3}>
                <div className={styles.toolLogo}>
                  <img src={algolia} alt="algolia" />
                </div>
                <div className={styles.toolLogo}>
                  <img className={styles.stripe} src={stripe} alt="stripe" />
                </div>
                <div className={styles.toolLogo}>
                  <img src={prismic} alt="prismic" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </CornerContainer>
  )
}

export default OurTools
