import React from "react"
import styles from "./StartAProjectBig.module.css"
import Brainstorm from "./contact.jpg"
import { ArrowRight } from "react-feather"
import { Link } from "gatsby"
import CornerContainer from "../CornerContainer/CornerContainer"

const StartAProject = () => {
  return (
    <CornerContainer backgroundColor="colorLightGrey">
      <Link to="/contact" className={styles.wrapper}>
        <div className={styles.container}>
          <div className={styles.content}>
            <div className={styles.boxForText}>
              <div className={styles.letsmakesomething}>
                <h3>LET'S MAKE SOMETHING GREAT TOGETHER</h3>
              </div>
              <div className={styles.arrowContainer}>
                <div className={styles.startLink}>
                  <h2>Start a Project 🚀</h2>
                  <div className={styles.arrow}>
                    <span className={styles.linez}></span>
                    <span className={styles.arrowz}>
                      <ArrowRight />
                    </span>
                  </div>
                </div>
              </div>

              <p>
                or
                <div>let's grab a coffee </div>
              </p>
            </div>
          </div>
          <div className={styles.imageContainer}>
            <img src={Brainstorm} alt="Siebe+Whiteboard" />
          </div>
        </div>
      </Link>
    </CornerContainer>
  )
}

export default StartAProject
