import React from "react"

const LinkArrow = ({ size = 11, fill = "#34F3CB" }) => {
  return (
    <svg width={size} height={size} viewBox="0 0 11 11" fill="none">
      <path
        d="M0.952327 0.285955L0.952327 1.94766L7.53431 1.95355L0.363071 9.12479L1.54158 10.3033L8.71282 3.13206L8.71872 9.71405L10.3804 9.71405V0.285955H0.952327Z"
        fill={fill}
      />
    </svg>
  )
}

export default LinkArrow
